// Customizable Area Start
import React from "react";

// Customizable Area Start
import {
  // Customizable Area Start
  Typography,
  // Customizable Area End
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid } from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import CourseDetailsController, { Props } from "./CourseDetailsController.web";
import Header from "../../../components/src/HeaderApp.web";
import Footer from "../../../components/src/Footer.web";
import "../assets/CourseDetails.scss";
import { list } from "./assets";
import CourseDetailHeader from "../../../components/src/CourseDetailHeader.web";
import FormDialog from "../../../components/src/FormDialog.web";
import StoreProducts from "../../../components/src/StoreProducts.web";
//@ts-ignore
import {
  NotificationContainer,
  //@ts-ignore
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import AssessmentForm from "../../../components/src/AssesmentForm";
import RelatedCourses from "../../../components/src/CourseList";
import ProductList from "../../../components/src/ProductList.web";
// Customizable Area End
export default class CourseDetails extends CourseDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  loadingRelatedCourse = () => {
    return <div>
      <div className="loader-class">
        <CircularProgress />
      </div>
    </div>
  }
  offlineRelated = () => {
    return <div className="div-offline-container">
      {" "}
      <h4>Not accessible offline.</h4>{" "}
    </div>
  }
  relatedCourseData = () => {
    return <div test-id="related-products-container-id" className="tab-container">
      <div className="course-header-title-container">
        <h3 className="course-header-title">
          {" "}
          Related Courses for {this.state?.courseData?.title}
        </h3>
      </div>
      <div className="container">
        <div className="wrapper-training w-50">
          {this.state.relatedCourses?.length <= 0 ? (
            <div>
              <h3>No Related Couses for this course</h3>
            </div>
          ) : (
            this.state.relatedCourses.map(
              (course: any, index: number) => {
                return (
                  <RelatedCourses
                    course={course}
                    index={index}
                    key={index}
                    list={list}
                    navigation={this.props.navigation}
                    getCourseData={this.getCourseData}
                    setState={this.setState}
                    setTabName={this.setTabName}
                    setValue={this.setTabValue}
                  />
                );
              }
            )
          )}
        </div>
      </div>
    </div>
  }
  relatedData = () => {
    if (this.state.isOffline && this.state.relatedCourseLoading) {
      return this.loadingRelatedCourse()
    } else if (!this.state.isOffline && !this.state.relatedCourseLoading) {
      return this.offlineRelated()
    } else {
      return this.relatedCourseData()
    }
  }

  /**
   * Related products
   */
  relatedProductsData = (relatedProducts: any) => {
    return <>
      <div className="course-header-title-container">
        <h3 className="course-header-title">
          {" "}
          Training Supplies related to {this.state?.courseData?.title}
        </h3>
        <div className="h3-disclaimer-training">
          <h3>
            The following products contain affiliate links. We may receive a commission if you make a purchase after clicking on one of these links.
          </h3>
        </div>
      </div>
      {this.state.relatedProducts?.length <= 0 ? (
        <div>
          <h3>
            No related Training Supplies for this course
          </h3>
        </div>
      ) :
        <div className="course-product-container">
          <ProductList products={relatedProducts} affiliate_count={this.updateAffiliateCounts} />
        </div>
      }
    </>
  }
  relatedProducts = (relatedProducts: any) => {
    if (this.state.isOffline && this.state.relatedProductsLoading) {
      return this.loadingRelatedCourse()
    } else if (!this.state.isOffline && !this.state.relatedProductsLoading) {
      return this.offlineRelated()
    } else {
      return this.relatedProductsData(relatedProducts)
    }
  }
  /** 
   * end
  */
  render() {
    const { relatedProducts, pageNumber, totalPages } = this.state;
    // const indexOfLastProduct = pageNumber * this.productsPerPage;
    // const indexOfFirstProduct = indexOfLastProduct - this.productsPerPage;
    // const currentProducts = responseProducts.slice(indexOfFirstProduct, indexOfLastProduct);
    return (
      // Customizable Area Start
      <div className="course-details-container" id="courseDetails-Container">
        <Header />
        <NotificationContainer />
        <CourseDetailHeader
          value={this.state.tabValue}
          setValue={this.setTabValue}
          proTips={this.state?.courseData?.course_pro_tips}
          relatedCourses={this.state.relatedCourses}
          relatedProducts={this.state.relatedProducts}
          reference={this.state?.courseData?.course_references}
          setTabName={this.setTabName}
          questions={this.state.assesmentQuestions}
          tabName={this.state.tabNames}
          courseTitle={this.state?.courseData?.title}
        />
        {!this.state.isOffline && (
          <div className="is-offline-container">
            <Typography variant="body2">You Are Offline Now.</Typography>
          </div>
        )}
        {this.state.tabNames === "Course" ? (
          this.state.isLoading ? (
            <div className="container-progress-spinner">
              <CircularProgress style={{ color: "black" }} />
            </div>
          ) : this.state?.courseData &&
            this.state?.courseData?.content !== null ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "auto",
              }}
            >
              <div
                className="pading-course-related"
                dangerouslySetInnerHTML={{
                  __html:
                    this.state?.courseData?.content ||
                    this.state.offlineCourseContent,
                }}
              />
            </div>
          ) : (
            <div className="center" style={{ paddingLeft: "20px" }}>
              No Course Data Available
            </div>
          )
        ) : (
          <></>
        )}
        {this.state.tabNames === "ReletedCourse" ? (
          this.relatedData()
        ) : null}
        {this.state.tabNames === "ReletedProducts" ? (
          this.relatedProducts(relatedProducts)
        ) : null}
        {this.state.tabNames === "References" && (
          <div className="tab-container">
            <div className="course-header-title-container">
              <h3 className="course-header-title">
                References for {this.state?.courseData?.title}
              </h3>
            </div>
            {this.state?.courseData?.course_references ? (
              <div
                className="pading-course"
                dangerouslySetInnerHTML={{
                  __html: this.state?.courseData?.course_references,
                }}
              />
            ) : (
              <div>No Details Are Available</div>
            )}
          </div>
        )}
        {this.state.tabNames === "ProTips" && (
          <div className="tab-container">
            <div className="course-header-title-container">
              <h3 className="course-header-title">Pro - Tips</h3>
            </div>
            {this.state?.courseData?.course_pro_tips ? (
              <div
                className="pading-course"
                dangerouslySetInnerHTML={{
                  __html: this.state?.courseData?.course_pro_tips,
                }}
              />
            ) : (
              <div>No Details Are Available</div>
            )}
          </div>
        )}
        {this.state.tabNames === "AssesmentForm" &&
          (!this.state.isOffline ? (
            <div className="div-offline-container">
              <h4>Not accessible offline.</h4>
            </div>
          ) : (
            <div className="container-center-data">
              <div className="course-header-title p-v-20">
                <h3 className="course-header-title">Course Assesment form</h3>
              </div>
              <AssessmentForm
                staticFields={this.state.staticFields}
                setFormdataLocal={this.setAssesmentFormData}
                shouldResetForm={this.state.shouldResetForm}
                setShouldResetForm={this.setShouldResetForm}
                handleModalOpen={this.handleModalOpen}
                courseData={this.state.courseData}
                questions={this.state.assesmentQuestions}
              />
              <FormDialog
                //@ts-ignore
                handleModalOpen={this.handleModalOpen}
                questions={this.state.assesmentQuestions}
                setFormdataLocal={this.setAssesmentFormData}
                handleAssesmentFormData={this.handleAssesmentFormData}
                openModal={this.state.openModal}
                setShouldResetForm={this.setShouldResetForm}
                shouldResetForm={this.state.shouldResetForm}
              />
            </div>
          ))}
        <Footer />
      </div>
      // Customizable Area End
    );
  }
}
// Customizable Area End
