import * as React from 'react'
//@ts-ignore
import { Grid } from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import StoreProducts from "./StoreProducts.web";

interface Props {
    // Customizable Area Start
    products: any[]
    totalPages?: number
    page?: number
    pageChange?: any
    affiliate_count?: any
    // Customizable Area End
}
const ProductList: React.FC<Props> = ({ products = [], totalPages=0, page=1, pageChange, affiliate_count }) => {

    return (
        <React.Fragment>
            <Grid container direction="column" alignItems="center" spacing={3}>
            <Grid item container direction="row" justifyContent="flex-start" alignItems="center" spacing={4}>
              {products.map((element: any, index: number) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <StoreProducts product={element} affiliate_count={affiliate_count} />
                </Grid>
              ))}
            </Grid>
            <Grid item style={{ marginTop: '20px' }}>
              {totalPages > 1 ? (
                <Pagination variant="outlined" shape="rounded" count={totalPages} page={page} onChange={pageChange} />
              ) : null}
            </Grid>
          </Grid>
        </React.Fragment>)
}
export default ProductList